import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Import Context
import { NavContextProvider } from './Context/NavContext';
import { PreviewSliderContextProvider } from './Context/PreviewSliderContext';

// Import Translation
import './i18n';

// Import Pages
import Header from './Components/Header/Header';
import Home from './Pages/Home/Home';
import Achievements from './Pages/Achievements/Achievements';

import Novelty from './Pages/Novelty/Novelty';
import Background from './Pages/Background/Background';
import Apply from './Pages/Apply/Apply';
import Contact from './Pages/Contact/Contact';
import Collaborators from './Pages/Collaborators/Collaborators';
import AboutPage from './Pages/About/AboutPage';

import CreationServicesPage from './Pages/Services/Creation_Services/Creation_Services';
import PruningServicesPage from './Pages/Services/Pruning_Services/Pruning_Services';
import MaintenanceServicesPage from './Pages/Services/Maintenance_Services/Maintenance_Services';
import PergolaServicesPage from './Pages/Services/Pergola_Services/Pergola_Services';

import ProductCategorySelectionPage from './Pages/Rental/ProductCategorySelectionPage';
import ProductSelectionPage from './Pages/Rental/ProductSelectionPage';
import ItemDetailPage from './Pages/Rental/ItemDetailPage';


// Import Style
import styled from 'styled-components';

// Website Under Maintenance
import WebSiteUnderMaintenance from './Components/WebSiteUnderMaintenance';


const HomeWrapper = styled.div`
  display: flex;
  flex-direction: row;  
`;

const ContentWrapper = styled.div`
  width: 100%;
`;


const App = () => {
  const { t, i18n } = useTranslation();

  return (
    <NavContextProvider>
      <PreviewSliderContextProvider>
        <React.StrictMode>
          <Router>
              <Header/>
              <HomeWrapper>
                <ContentWrapper>
                <Routes>
                  <Route path={`/${i18n.language}`} element={<Home />} />
                  <Route path={`/${i18n.language}/${t('Achievements')}`} element={<Achievements />} />
                  <Route path={`/${i18n.language}/${t('ForestryWork')}`} element={<PruningServicesPage />} />
                  <Route path={`/${i18n.language}/${t('Maintenance')}`} element={<MaintenanceServicesPage />} />
                  <Route path={`/${i18n.language}/${t('Creation')}`} element={<CreationServicesPage />} />
                  <Route path={`/${i18n.language}/${t('Pergola')}`} element={<PergolaServicesPage />} />
                  

                  <Route path={`/${i18n.language}/${t('Novelty')}`} element={<Novelty />} />
                  <Route path={`/${i18n.language}/${t('Background')}`} element={<Background />} />
                  <Route path={`/${i18n.language}/${t('About')}`} element={<AboutPage />} />

                  <Route path={`/${i18n.language}/${t('Rental')}`} element={<ProductCategorySelectionPage />}/>
                  <Route path={`/${i18n.language}/${t('Rental')}/${t('Tool')}`} element={<ProductSelectionPage productCategory='Tool'/>}/>
                  <Route path={`/${i18n.language}/${t('Rental')}/${t('Machines')}`} element={<ProductSelectionPage productCategory='Machine'/>}/>

                  <Route path={`/${i18n.language}/${t('Rental')}/${t('Tool')}/:id`} element={<ItemDetailPage itemCategory='Tool'/>}/>
                  <Route path={`/${i18n.language}/${t('Rental')}/${t('Machines')}/:id`} element={<ItemDetailPage itemCategory='Machine'/>}/>

                  <Route path={`/${i18n.language}/${t('Apply')}`} element={<Apply />} />
                  <Route path={`/${i18n.language}/${t('Contact')}`} element={<Contact />} />
                </Routes>
                </ContentWrapper>
              </HomeWrapper>
          </Router>
        </React.StrictMode>
      </PreviewSliderContextProvider>
    </NavContextProvider>
  );
} 
/*
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);
*/


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <WebSiteUnderMaintenance/>
);

