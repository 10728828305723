// Import React Libraries
import React from 'react';

// Import PropTypes
import PropTypes from 'prop-types';

// Import Style
import { StyledAwesomeIcon } from '../../Utils/Styles/IconStyle';

// Import Constant
import { generalTextFontSize } from '../../Utils/Constants/Constant';

// Import Color
import identityColors from '../../Utils/Colors/Identity_Colors';

class CopyEmailLink extends React.Component {
  constructor (props) {
    super(props);
    this.emailLinkRef = React.createRef();
    this.state = {
      isCopied: false
    };
  }

  copyToClipboard = (e) => {
    e.preventDefault();
    const emailLink = this.emailLinkRef.current;
    const range = document.createRange();
    range.selectNode(emailLink);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand('copy');
    window.getSelection().removeAllRanges();

    // Open the user's email software
    window.location.href = `mailto:${this.props.email}`;

    this.setState({ isCopied: true });

    setTimeout(() => {
      this.setState({ isCopied: false });
    }, 2000);
  };

  renderContent () {
    if (this.props.icon) {
      // If 'picture' prop is provided, render the picture
      return <StyledAwesomeIcon icon={this.props.icon} style={{ color: `${identityColors.lightGreen}`}}/>;
    } else {
      // If 'picture' prop is not provided, render the email
      return this.props.email;
    }
  }

  render () {
    return (
      <div>
        <a
          style={{
            color: 'black',
            fontSize: `${generalTextFontSize}px`,
            fontFamily: 'sans-serif',
            margin: '5px',
            cursor: 'pointer' // Fixing typo in cursor property
          }}
          href={`mailto:${this.props.email}`}
          ref={this.emailLinkRef}
          onClick={this.copyToClipboard}
        >
          {this.renderContent()}
        </a>
        {this.state.isCopied && (
          <span
            style={{
              position: 'fixed',
              bottom: '10px',
              left: '50%',
              transform: 'translateX(-50%)',
              padding: '10px',
              zIndex: 1000,
              ...this.props.style
            }}
          >
            Email Copied To Clipboard
          </span>
          
        )}
      </div>
    );
  }
}

const MailPopUpStyleType = PropTypes.shape({
  fontFamily: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  borderRadius: PropTypes.string.isRequired,
  fontWeight: PropTypes.string.isRequired
});

CopyEmailLink.propTypes = {
  email: PropTypes.string.isRequired,
  style: MailPopUpStyleType.isRequired,
  icon: PropTypes.string
};

export default CopyEmailLink;
