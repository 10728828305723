// Import Styled
import styled from "styled-components";

// Import Constant
import { responsiveWidthTablet } from "../../Constants/Constant";

export const InterventionZoneTextImageContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  @media (max-width: ${responsiveWidthTablet}px){
    gap: 10px;
    flex-direction: column;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 50%;
  gap: 10px;

  @media (max-width: ${responsiveWidthTablet}px){
    width: 80%;
  }
`;