// Import Styled
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Import Constants
import { responsiveWidthMobile, responsiveWidthTablet } from "../../Constants/Constant";
import { 
  HomeServicePresentationImageHeight,  
  HomeServicePresentationServiceCategoryWrapperHeight,
  HomeServicePresentationResponsiveMobileImageHeight, HomeServicePresentationResponsiveTabletImageHeight, HomeServicePresentationResponsiveTabletServiceCategoryWrapperHeight, HomeServicePresentationResponsiveMobileServiceCategoryWrapperHeight
} from "../../Constants/Home/ServicePresentation";

// Import Colors
import identityColors from "../../Colors/Identity_Colors";


// Services Home Related
export const HomeServicePresentationWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;

  @media (max-width:${responsiveWidthTablet}px) {
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
  }

  @media (max-width:640px) {
    grid-template-columns: 1fr;
  }
`;

export const HomeServicePresentationCategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  height: ${HomeServicePresentationServiceCategoryWrapperHeight}px;

  @media (max-width:${responsiveWidthTablet}px) {
    height: ${HomeServicePresentationResponsiveTabletServiceCategoryWrapperHeight}px;
  }

  @media (max-width:${responsiveWidthMobile}px) {
    height: ${HomeServicePresentationResponsiveMobileServiceCategoryWrapperHeight}px;
  }
`;

export const HomeServicePresentationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0 10px 0;

  border-radius: 5%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2), 0 0 10px ${identityColors.lightGreen};

  height: 80%;
`;

export const HomeServicePresentationImageContainer = styled.div`

  height: ${HomeServicePresentationImageHeight}px;

  @media (max-width:${responsiveWidthTablet}px) {
    height: ${HomeServicePresentationResponsiveTabletImageHeight}px;
  }

  @media (max-width:${responsiveWidthMobile}px) {
    height: ${HomeServicePresentationResponsiveMobileImageHeight}px;
  }
`;

export const HomeServicePresentationImage = styled.img`

  height: ${HomeServicePresentationImageHeight}px;

  @media (max-width:${responsiveWidthTablet}px) {
    height: ${HomeServicePresentationResponsiveTabletImageHeight}px;
  }

  @media (max-width:${responsiveWidthMobile}px) {
    height: ${HomeServicePresentationResponsiveMobileImageHeight}px;
  }
`


export const HomeServicePresentationListContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    height: 90%;    
`;

// ul
export const HomeServicePresentationUL = styled.ul`
  list-style-type: none; 
  margin: 0;
  padding: 5px 0;
`;

// Icon
export const HomeServicePresentationIcon = styled(FontAwesomeIcon)`
  width: 10px;
  height: 10px;
  margin-left: 5px;
`;

// Button

export const HomeServicePresentationButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
`;

export const HomeServicePresentationButton = styled.button`

padding: 12px 18px;

font-weight: 600;
font-size: 12px;
text-transform: uppercase;

cursor: pointer;

background: ${identityColors.lightGreen};
color: white;

border: none;
border-radius: 5px;
  display: flex;
  align-items: center;

  ${HomeServicePresentationIcon} {
      display: none;
  }

  &:hover {
    ${HomeServicePresentationIcon} {
        display: inline;
    }
  }
`;
