// Import Style
import styled from 'styled-components';

// Import Constants
import { responsiveWidthTablet, responsiveWidthMobile } from '../../Constants/Constant';

// Import Colors
import identityColors from '../../Colors/Identity_Colors';

export const DesignUnderTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

`;

// Design Leaves Under Title
export const DesignUnderTitleImageContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 75px;
  width: 100%;

  @media (max-width: ${responsiveWidthTablet}px){
  }
  @media (max-width: ${responsiveWidthMobile}px){
  }
`;


export const DesignUnderTitleImage = styled.img`
  width: 400px;
  height: 100px;
  
  @media (max-width: ${responsiveWidthMobile}px){
    height: 75px;
  }
`;

// Design Leaves Around Title

export const DesignAroundTitleWrapper = styled.div`
  display: flex;
  
  justify-content: space-between;
  align-items: center;

  height: 75px;
  width: 100%;

  @media (max-width: ${responsiveWidthTablet}px){
  }
  @media (max-width: ${responsiveWidthMobile}px){
  }

`;

export const DesignAroundTitleImageContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 75px;
  width: 300px;

  @media (max-width: ${responsiveWidthTablet}px){
  }
  @media (max-width: ${responsiveWidthMobile}px){
  }

`;


export const DesignAroundTitleImage = styled.img`
  height: 100px;
  width: 100%;
`;

export const DesignAroundTitle = styled.h1`
  font-family: sans-serif;
  font-size: 35px;

  font-style: italic;

  color: ${identityColors.lightGreen} ;

  text-align: center;

  @media (max-width: ${responsiveWidthMobile}px){
    font-size: 20px;
  }
`;
