// Item Details
export const IllustrationDisplayWidth = 750;
export const IllustrationDisplayWidthResponsiveTablet = 450;
export const IllustrationDisplayWidthResponsiveMobile = 300;

export const IllustrationDisplayHeight = 600;
export const IllustrationDisplayHeightResponsiveTablet = 350;
export const IllustrationDisplayHeightResponsiveMobile = 250;

export const PicturePreviewWidth = 100;
export const PicturePreviewWidthResponsiveTablet = 75;
export const PicturePreviewWidthResponsiveMobile = 50;
export const PicturePreviewHeight = 100;
export const PicturePreviewHeightResponsiveTablet = 75;
export const PicturePreviewHeightResponsiveMobile = 50;

export const PicturelayoutButtonWidth = 40;

// Preview Slider
export const previewSliderElementWidth = 150;
export const previewSliderElementHeight = 150;
export const previewSliderElementGap = 11;
export const previewSliderElementBorderWith = 1;
export const previewSliderAmountOfPictureDisplayed = 4;

// Trending Slider
export const trendingSliderElementHeight = 150;
export const trendingSliderElementWidth = 285;
export const trendingSliderElementGap = 16;
export const trendingSliderElementPictureWidth = 150;
export const trendingSliderElementPictureHeight = 150;
export const trendingSliderElementBorderWith = 2;

export const trendingSliderMaxItem = 8;

// ProductCategorySelectionImage
export const productCategorySelectionImageWidth = 150;
export const productCategorySelectionImageHeight = 150;