// Product Selection Image Container 
export const ProductSelectionImageContainerHeight = 150;
export const ProductSelectionImageContainerWidth = 170;

// Product Selection Image
export const ProductSelectionImageHeight = 150;
export const ProductSelectionImageWidth = 150;

// ¨Product Selection Data Container
export const ProductSelectionDatasContainerHeight = 40;

// Production Selection Container
const ProductSelectionContainerPadding = 10;
export const ProductSelectionContainerHeight = ProductSelectionImageContainerHeight + ProductSelectionDatasContainerHeight + ProductSelectionContainerPadding;
export const ProductSelectionContainerWidth = ProductSelectionImageContainerWidth + ProductSelectionContainerPadding;
