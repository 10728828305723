// Import Style
import styled from 'styled-components';
import { commonHeaderFooter } from '../../Utils/Styles/GlobalStyles';

// Import Colors
import colors from '../Colors/Colors';

// Import Constants
import { headerHeight, horizontalMargin, zIndexHeader } from '../Constants/Constant';

const sideMargin = 20;

export const HeaderWrapper = styled.div`
  ${commonHeaderFooter};
  display: flex;

  justify-content: space-between;

  position: fixed;
  top: 0;

  height: ${headerHeight}px;
  
  color: black;
  background-color: ${colors.headerBackGround};

  z-index: ${zIndexHeader};

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
`;

export const LogoDiv = styled.div`

  margin-left: ${sideMargin}px;

`;

export const NavBarDiv = styled.div`
  margin-right: ${horizontalMargin}px;

  box-sizing: border-box;
`;
