// Import Style
import styled from 'styled-components';

// Animated Icon
export const AnimatedIconStripWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;

  padding: 20px 0;

  gap: 10px;

`;

export const AnimatedIconStripContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 10px;

  align-items: center;
  width: 165px;


  flex: 0 0 calc(30% - 10px)

  @media (max-width: 1100px){
    flex: 0 0 calc(40% - 10px);
  }

`;

// Incremental Animation

export const NumberAnimationContainer = styled.div`
  font-size: 24px;
  text-align: center;
  animation: increaseNumber 2s ease-in-out forwards;
`;

export const Number = styled.span`
  display: inline-block;
  width: 2em;
  text-align: center;
`;
