// Import Style
import styled from 'styled-components';
import { responsiveWidthTablet } from '../Constants/Constant';

export const ValuesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 10px;

  @media (max-width: ${responsiveWidthTablet}px){
      align-items: center;
  }

  @media (max-width: 400px){
    gap: 5px;
  }

`;

export const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 165px;
  align-items: center;

  flex: 0 0 calc(15% - 5px);

  @media (max-width: 1100px){
    flex: 0 0 calc(30% - 10px);
  }

  @media (max-width: 650px){
    flex: 0 0 calc(45% - 10px);
  }

  @media (max-width: 450px){
    flex: 0 0 calc(48% - 0px);
  }

  @media (max-width: 345px){
    flex: 0 0 calc(80% - 10px);
  }
`;