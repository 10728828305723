// Import Style
import styled from 'styled-components';

// Import Constants
import { generalTextFontSize, responsiveWidthMobile, responsiveWidthTablet } from '../../Constants/Constant';

export const AboutCertificationWrapper = styled.div`

`;


export const AboutCertificationTextImageContainer = styled.div`
  display: flex;
  justify-content: space-evenly;

  @media (max-width: ${responsiveWidthTablet}px){
    gap: 10px;
    align-items: center;
    flex-direction: column-reverse;
  }
`;

export const AboutCerticficationTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-evenly;


  width: 55%;
  gap: 10px;

  @media (max-width: ${responsiveWidthTablet}px){
    width: 80%;
  }
  @media (max-width: ${responsiveWidthMobile}px){
    width: 90%; 
  }
`;


export const AboutPageDescriptionP = styled.p`
  margin: 8px;
  font-family: sans-serif;
  font-size: ${generalTextFontSize}px;
  padding: 0px;
`;

export const AboutPageListContainer = styled.div`
  display: flex;
`;


export const AboutCertificationImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 250px;
  width: 40%;

  @media (max-width: ${responsiveWidthTablet}px){
    width: 60%;
  }

  @media (max-width: ${responsiveWidthMobile}px){
    height: 150px;
    width: 90%; 
  }
`;

export const AboutCertificationImage = styled.img`
  width: 50%;
  
  @media (max-width: ${responsiveWidthMobile}px){
    height: 150px;
    width: 100%; 
  }
`;


