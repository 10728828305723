// Import Styled
import styled from "styled-components";

// Import Constants
// import { responsiveWidthMobile, responsiveWidthTablet } from "../../Constants/Constant";
import { CompaniePresentationTopIllustrationHeight, 
  //CompaniePresentationTopIllustrationTabletHeight,
  //CompaniePresentationTopIllustrationMobileHeight 
} from "../../Constants/Home/CompaniePresentation";

import { headerHeight } from "../../Constants/Constant";

export const CompaniePresentationTopIllustrationContainer = styled.div`
  height: ${CompaniePresentationTopIllustrationHeight}px;  
  width: 100%;
  margin-top: ${headerHeight}px;
`;

export const CompaniePresentationTopIllustration = styled.img`
  height: ${CompaniePresentationTopIllustrationHeight}px;
  width: 100%;
`;

export const CompaniePresentationTopIllustrationContainerMobile = styled.div`
  margin-top: ${headerHeight}px;
  height: 300px;
  width: 100%;
`;

export const CompaniePresentationTopIllustrationMobile = styled.img`
  height: 300px;
  width: 100%;
`;

export const CompaniePresentationTopIllustrationLogoContainerMobile = styled.div`
  display: flex;
  justify-content: center;
  height: 200px;
  width: 100%;
`;

export const CompanieTopIllustrationLogoMobile = styled.img`
  height: 200px:
  width: 65%;
`;

/*
  @media (max-width:${responsiveWidthTablet}px) {
    max-height: ${CompaniePresentationTopIllustrationTabletHeight}px;
  }

  @media (max-width:${responsiveWidthMobile}px) {
    max-height: ${CompaniePresentationTopIllustrationMobileHeight}px;
  }
*/

export const CompaniePresentationTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const CompaniePresentationText = styled.p`
  font-family: sans-serif;
  font-size: 20px;
  margin: 0px;
  padding: 0px;
  width: 90%;
`;