// Import React Libs
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Import Style
import styled, { css } from 'styled-components';

// Import Constants
import { responsiveWidthTablet } from '../Constants/Constant';
import colors from '../Colors/Colors';


export const IconContainerStyle = css`
    
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 60px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 50%;

    @media (max-width: ${responsiveWidthTablet}px){
        text-align:center;
        margin:0;
    }
`;

export const IconContainer = styled.div`
    ${IconContainerStyle};
`;

export const CustomIconContainer = styled(IconContainer)`
    margin-right: 30px;

    @media (max-width: ${responsiveWidthTablet}px){
        margin: 0;
    }
`;


export const InstagramCustomIconContainer = styled(IconContainer)`

    margin-right: 30px;
    
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);

    border-radius: 25%;

    @media (max-width: ${responsiveWidthTablet}px){
        margin: 0;
    }
`;

export const IconStyle = css`
    height: 45px;
    width: 45px;

    transition: transform 0.5s ease-in-out;

    &:hover {
        cursor: pointer;
        transform: scale(1.1);
    }
`;

export const StyledAwesomeIcon = styled(FontAwesomeIcon)`
    ${IconStyle};
    height: 35px;
    width: 35px;
    
`;

// Icon

export const CommonLinksIcon = css`
  width: 25px;
  height: 25px;
  color: white;

  &:hover{
      color: ${colors.orangeCustom};
  }

`;

export const StyledIcon = styled(FontAwesomeIcon)`
  ${CommonLinksIcon};
`;

export const NewServiceIconContainer = styled.div`

    position: relative;
`;

export const NewIcon = styled(FontAwesomeIcon)`
    width: 35px;
    height: 35px;
`;

export const NewText = styled.span`
    font-family: sans-serif;
    position: absolute;
    top: 50%;
    left: 38%;
    transform: translate(-50%, -50%) rotate(-45deg);
    font-size: 13px; 
    color: white;
    font-weight: bold;
`;