// Service Presentation

// Computer
export const HomeServicePresentationTitleHeight = 0;
export const HomeServicePresentationButtonHeight = 20;
export const HomeServicePresentationGapHeight = 20;

export const HomeServicePresentationTextHeight = 260;
export const HomeServicePresentationImageHeight = 200;

export const HomeServicePresentationServiceCategoryWrapperHeight =  
  HomeServicePresentationGapHeight +   
  HomeServicePresentationImageHeight +
  HomeServicePresentationTitleHeight +
  HomeServicePresentationTextHeight + 
  HomeServicePresentationButtonHeight;

// Responsive Design Tablet
export const HomeServicePresentationResponsiveTabletTextHeight = 260;
export const HomeServicePresentationResponsiveTabletImageHeight = 200;
export const HomeServicePresentationResponsiveTabletServiceCategoryWrapperHeight = 
  HomeServicePresentationGapHeight +
  HomeServicePresentationResponsiveTabletImageHeight + 
  HomeServicePresentationTitleHeight + 
  HomeServicePresentationResponsiveTabletTextHeight + 
  HomeServicePresentationButtonHeight;

// Responsive Design Mobile
export const HomeServicePresentationResponsiveMobileTextHeight = 260;
export const HomeServicePresentationResponsiveMobileImageHeight = 200;
export const HomeServicePresentationResponsiveMobileServiceCategoryWrapperHeight =
  HomeServicePresentationGapHeight +
  HomeServicePresentationResponsiveMobileImageHeight + 
  HomeServicePresentationTitleHeight + 
  HomeServicePresentationResponsiveMobileTextHeight + 
  HomeServicePresentationButtonHeight;

