// Import Styled
import styled from 'styled-components';

// Import Constants
import { generalTextFontSize, responsiveWidthMobile, responsiveWidthTablet } from '../../Constants/Constant';


export const AboutPageTextImageContainer = styled.div`
  display: flex;
  justify-content: space-evenly;

  @media (max-width: ${responsiveWidthTablet}px){
    gap: 10px;
    align-items: center;
    flex-direction: column-reverse;
  }
`;

export const AboutPageTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-evenly;


  width: 55%;
  gap: 10px;

  @media (max-width: ${responsiveWidthTablet}px){
    width: 80%;
  }
  @media (max-width: ${responsiveWidthMobile}px){
    width: 90%; 
  }
`;

export const AboutPageDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AboutPageDescriptionP = styled.p`
  margin: 8px;
  font-family: sans-serif;
  font-size: ${generalTextFontSize}px;
  padding: 0px;
`;

export const AboutPageListContainer = styled.div`
  display: flex;
`;


export const AboutPageImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;

  @media (max-width: ${responsiveWidthTablet}px){
    width: 60%;
  }

  @media (max-width: ${responsiveWidthMobile}px){
    width: 90%; 
  }

`;

export const AboutPageImage = styled.img`
  width: 100%;
`;


