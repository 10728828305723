// Import Style
import styled, { css } from 'styled-components';

// Import Colors
import colors from '../Colors/Colors';
import identityColors from '../Colors/Identity_Colors';

// Import Constants
import { footerHeight, responsiveWidthMobile } from '../../Utils/Constants/Constant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const FooterWrapper = styled.div`
    display: flex;
    flex-direction: row;

    align-items:center;
    justify-content: space-between;

    width: 100%;
    height: ${footerHeight}px;

    color: ${identityColors.lightGreen};
    background: ${colors.backgroundHeaderFooter};

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
`;

export const FooterSideContainerLeft = styled.div`
    display: flex;
    align-items: center;
    height: ${footerHeight}px;
    width: 25px;

    margin: 0 20px;

    @media (max-width: ${responsiveWidthMobile}px){
        width: 0;
        margin: 0;
    }
`;

export const FooterSideContainerRight = styled.div`
    display: flex;
    align-items: center;
    height: ${footerHeight}px;
    width: 25px;

    margin: 0 20px;
`;

export const FooterTextContainer = styled.div`
`;

export const FooterText = styled.p`
    font-family: sans-serif;
    font-size: 15px;
`;


//Icon 

const IconDimension = css`
  height: 25px;
  width: 25px;
`;

export const IconContainerIn = styled.div`
  ${IconDimension};
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const IconContainerOut = styled.div`
  ${IconDimension};
  position: absolute;
  top: 10px;
  right: -45px;
`;

export const StyledIcon = styled(FontAwesomeIcon)`
  ${IconDimension};
  cursor: pointer;
  &:hover{
    color: ${colors.linkHover};
  }
`;