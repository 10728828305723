// Import Style
import styled, { css } from 'styled-components';
import colors from '../Colors/Colors';
import { responsiveWidthTablet } from '../Constants/Constant';
import { PicturelayoutButtonWidth } from '../Constants/Picture_Constants';
import identityColors from '../Colors/Identity_Colors';

// Services Related


export const ButtonStyle = css`
  padding: 12px 18px;

  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;

  cursor: pointer;

  background: ${identityColors.lightGreen};
  color: white;

  border: none;
  border-radius: 5px;
`;



// Slider Related
export const SliderScrollingButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: ${PicturelayoutButtonWidth}px;
`;

const ScrollingButtonStyle = css`

  border: none;
  margin: 0;
  color: white;

  width: ${PicturelayoutButtonWidth}px;
  background-color: ${colors.marineBlue};

  @media (max-width: ${responsiveWidthTablet}px){
    margin: 2px;
  }

  &:hover{
  }
`;

export const ScrollingButtonCircular = styled.button`
  ${ScrollingButtonStyle};
  height: 40px;

  border-radius: 50%;
`;

export const ScrollingButtonSquare = styled.button`
  ${ScrollingButtonStyle};
  height: 80px;
`;
