// Import Styled
import styled from 'styled-components';
import { responsiveWidthMobile, responsiveWidthTablet } from '../Constants/Constant';

export const ContactWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;



export const ContactTypeWrapper = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;

  position: relative;

  margin: 0 auto;
  width: 100%;
  flex: 0 0 calc(45% - 10px);
`;

export const ContactTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 80%;
`;

export const ContactContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;

    @media (max-width: ${responsiveWidthTablet}px){
        flex-direction: column;
        padding: 0;
    }
`;

export const ContactTextContainer = styled.div`
    display: flex;
    flex-direction: column;

    @media (max-width: ${responsiveWidthTablet}px){
        text-align:center;
    }
`;

export const ContactText = styled.p`
  font-family: sans-serif;
  font-size: 20px;
  margin: 0px;
  padding-right: 5px;

  @media (max-width: ${responsiveWidthTablet}px){
    padding: 0px;
}
`;


// Address Component 

export const AddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  

  @media (max-width: ${responsiveWidthMobile}px){

    flex-direction: column;
  }
`;

export const AdressContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${responsiveWidthTablet}px){

    flex-direction: column;
  }
`;

export const MapContainer = styled.div`
  text-align: center;
  padding: 20px;

  position: relative;

  width: 80%;

  border-radius: 15px;

  flex-grow: 1;
  

  @media (max-width: ${responsiveWidthMobile}px){

    width: 90%;
  }
`;