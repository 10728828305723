// Responsive Design
export const responsiveWidthTablet = 990;
export const responsiveWidthMobile = 450;

// General Layout
export const headerHeight = 50;
export const footerHeight = 50;

export const verticalMargin = 20;

export const horizontalMargin = 20;
export const horizontalMarginTablet = 15;
export const horizontalMarginMobile = 10;

// Global Text Font Size
export const titleFontSize = 50;
export const subTitleFontSize = 30;
export const generalTextFontSize = 20;

// DropDown
export const dropDownLinkHeight = 40;

// Pofile Pic
export const profilePicSize = 400;
export const profilePicSizeResponsive = 200;

// Top Illustration
export const topIllustrationHeight = 400;

// z-index
export const zIndexHeader = 1;

export const zIndexCart = 0;
export const zIndexCartButton = 0;
export const zIndexCartCloseButton = 0;

export const zIndexBurgerButton = 2;
export const zIndexNavBar = 1;

export const zIndexSideNav = 1;