// Import Style
import styled from 'styled-components';

// Import Colors
import identityColors from '../Colors/Identity_Colors';

// Import Constants
import { responsiveWidthMobile, responsiveWidthTablet } from '../Constants/Constant';
import { productCategorySelectionImageHeight, productCategorySelectionImageWidth } from '../Constants/Picture_Constants';


// Wrap all the Product Category Choice
export const ProductCategorySelectionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

/** Contains each category choice : 
 * - Category Image Container
 * - Category Image
 * - Category Name
 */
export const ProductCategorySelectionContainer = styled.div`
  display:flex;
  
  flex: 0 0 calc(23% - 10px);

  border-radius: 15px;
  box-shadow: 5px 5px 10px 5px rgba(0.1, 0, 0.1, 0.2);
  
  height: 200px;
  width: 250px;
  
  margin: 10px 16px;

  &:hover{
    outline: 2px solid ${identityColors.darkGreen};
    box-shadow: none;
  }

  @media (max-width: ${responsiveWidthTablet}px){
    flex: 0 0 calc(45% - 10px);
  }
  @media (max-width: ${responsiveWidthMobile}px){
    flex: 1 0 calc(50% - 10px);
  }
`;

// Contains the category image
export const ProductCategorySelectionImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: ${productCategorySelectionImageWidth}px;
`;

// Category Image
export const ProductCategorySelectionImage = styled.img`
  max-height: ${productCategorySelectionImageWidth}px;
  max-width: ${productCategorySelectionImageHeight}px;
`;

// Category Name
export const ProductCategoryName = styled.h2`
  font-family: sans-serif;
  font-size: 25px;

  color: ${identityColors.darkGreen};
  
  margin: 5px 0;

  @media (max-width: ${responsiveWidthMobile}px){
      text-align:center;
  }  
`;