// Import Style
import styled from 'styled-components';

// Import Constants
import { responsiveWidthMobile, responsiveWidthTablet } from '../Constants/Constant';

// Get the container element
const screenWidth = window.innerWidth;
const heightWithRatio = 3/4* screenWidth;

console.log('heightWithRatio : ',heightWithRatio);

export const PicturePage = styled.img`
  height: 300px;
  width: 400px;

  @media (max-width: ${responsiveWidthTablet}px){

  }

  @media (max-width: ${responsiveWidthMobile}px){
    width: 90%; 
    height: ${heightWithRatio} ; 
  }

`;