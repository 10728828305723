// Import Style
import styled from 'styled-components';

// Import Constants
import { responsiveWidthMobile, responsiveWidthTablet } from "../Constants/Constant";
import { IllustrationDisplayHeight, IllustrationDisplayHeightResponsiveMobile, IllustrationDisplayHeightResponsiveTablet, IllustrationDisplayWidth, IllustrationDisplayWidthResponsiveMobile, IllustrationDisplayWidthResponsiveTablet, PicturelayoutButtonWidth } from "../Constants/Picture_Constants";

export const SliderDisplayer = styled.div`
  display: flex;
  justify-content: center;
`;

export const PictureDisplayer = styled.div`
  width: ${IllustrationDisplayWidth}px;
  height: ${IllustrationDisplayHeight}px;

  display: flex;
  justify-content: center;
  align-items: center;
  
  @media (max-width: ${responsiveWidthTablet}px){
    width: ${IllustrationDisplayWidthResponsiveTablet}px;
    height: ${IllustrationDisplayHeightResponsiveTablet}px;
    align-items: center;
  }

  @media (max-width: ${responsiveWidthMobile}px){
    width: ${IllustrationDisplayWidthResponsiveMobile}px;
    height: ${IllustrationDisplayHeightResponsiveMobile}px;
  }
`;

export const PictureContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${IllustrationDisplayWidth - PicturelayoutButtonWidth * 2}px;
  height: ${IllustrationDisplayHeight}px;

  @media (max-width: ${responsiveWidthTablet}px){
    width: ${IllustrationDisplayWidthResponsiveTablet}px;
    height: ${IllustrationDisplayHeightResponsiveTablet}px;
    align-items: center;
  }

  @media (max-width: ${responsiveWidthMobile}px){
    width: ${IllustrationDisplayWidthResponsiveMobile}px;
    height: ${IllustrationDisplayHeightResponsiveMobile}px;
  }

`;

export const ProductPicture = styled.img`
  max-width: ${IllustrationDisplayWidth - PicturelayoutButtonWidth * 2}px;
  max-height: ${IllustrationDisplayHeight}px;
  object-fit: contain;

  @media (max-width: ${responsiveWidthTablet}px){
    width: ${IllustrationDisplayWidthResponsiveTablet - PicturelayoutButtonWidth * 2}px;
    height: ${IllustrationDisplayHeightResponsiveTablet}px;
  }

  @media (max-width: ${responsiveWidthMobile}px){
    width: ${IllustrationDisplayWidthResponsiveMobile - PicturelayoutButtonWidth * 2}px;
    height: ${IllustrationDisplayHeightResponsiveMobile}px;
  }
`;

// LightBox


