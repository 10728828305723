// Import Style
import styled from 'styled-components';

// Import Colors
import colors from '../Colors/Colors';

// Import Constants
import { responsiveWidthMobile, responsiveWidthTablet } from '../Constants/Constant';
import identityColors from '../Colors/Identity_Colors';





// Service Detail Related

export const ServiceDetailWrapper = styled.div`
    display: flex;
    justify-content: space-evenly;

    position: relative;
    padding: 50px 0;

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 30%;
        width: 33%; 
        border-bottom: 1px solid ${colors.bluePastel}; 
      }

    &:last-child {
        &:before {
            content: none; 
        }
    }
    
    @media (max-width: ${responsiveWidthMobile}px){
        flex-direction:column;
        align-items: center;
        gap: 1rem;
    }
    
`;

export const ServiceDetailTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    width:300px;

`;

export const ServiceDetailTitle = styled.h1`
    font-size:25px;
    font-weight:bold;
    font-family: sans-serif;


    color: ${colors.bluePastel};

    position: relative;
    display: inline-block;

    margin: 0;

    width: fit-content;
    height: 40px;

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 25%;
        width: 50%;
        border-bottom: 2px solid ${colors.bluePastel}; 
    }

    @media (max-width: ${responsiveWidthMobile}px){
        &:before {
             
        }
    }
`;

export const ServiceDetailDescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;

    width: 600px;

    @media (max-width: ${responsiveWidthTablet}px){
        width:300px;
    }
`;

export const ServiceDetailDescriptionUl = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
        position: relative;
        display: flex;

        &::before {
            content: "\\2714";
            margin-right: 1.5em; 

            @media (max-width: ${responsiveWidthTablet}px){
                margin-right: 0.55em; 
            }
        }
    }
`;

export const InnerUl = styled.li`
    margin: 0;
`;