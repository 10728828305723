// Import Colors
import identityColors from "./Identity_Colors";


const colors = {
  link: '#ffffff',
  linkResponsive: '#000000',

  title: identityColors.darkGreen,

  burgerDiv: '#ffffff',
  burgerDivOpen: '#000000',

  headerBackGround: '#FFFFFF',
  navLink: identityColors.lightGreen,
  navLinkUnderline: identityColors.darkGreen,

  burgerButtonOpenMenu: '#000000',
  burgerButtonCloseMenu: '#000000',

  navDropDownTitle: identityColors.lightGreen,
  navDropDownOption: identityColors.lightGreen,
  navDropDownBackGround: '#FFFFFF',
  navDropDownHover: '#e8e7e6'
};
export default colors;
