// Import Style
import styled from 'styled-components';

// Import Constants
import { responsiveWidthMobile, responsiveWidthTablet } from '../Constants/Constant';
import { 
  ProductSelectionContainerHeight,
  ProductSelectionImageHeight, ProductSelectionImageWidth, 
  ProductSelectionDatasContainerHeight, 
  ProductSelectionImageContainerHeight,
  ProductSelectionImageContainerWidth,
  ProductSelectionContainerWidth} 
  from '../Constants/ProductSelection_Constants';

import identityColors from '../Colors/Identity_Colors';

export const ProductSelectionWrapper = styled.div`
  max-width: 80%;
  margin: 0 auto;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 2rem;

  @media (max-width:${responsiveWidthTablet}px) {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0.5rem;
  }

  @media (max-width:640px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width:${responsiveWidthMobile}px) {
    grid-template-columns: 1fr;
  }
`;

export const ProductSelectionContainer = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;

  border-radius: 15px;
  box-shadow: 5px 5px 10px 5px rgba(0.1, 0, 0.1, 0.2);

  height: ${ProductSelectionContainerHeight}px;
  width: ${ProductSelectionContainerWidth}px;
  margin: 10px 16px;
  
  &:hover{
    outline: 2px solid ${identityColors.darkGreen};
    box-shadow: none;
  }
`;


export const ProductSelectionImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: ${ProductSelectionImageContainerHeight}px;
  width: ${ProductSelectionImageContainerWidth}px;
`;

export const ProductSelectionImage = styled.img`
  max-height: ${ProductSelectionImageHeight}px;
  max-width: ${ProductSelectionImageWidth}px;
`;

/** Contains the data related to the product
 * - Product Name
 * 
 */
export const ProductDatasContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-evenly;
  height: ${ProductSelectionDatasContainerHeight}px;
`;

// Product Name
export const ProductName = styled.h2`
  font-family: sans-serif;
  font-size: 25px;

  color: ${identityColors.darkGreen};
  
  margin: 5px 0;

  @media (max-width: ${responsiveWidthMobile}px){
      text-align:center;
  }  
`;
