// Import Style
import styled from 'styled-components';

// Import Constants
import { previewSliderElementHeight, previewSliderElementWidth } from '../Constants/Picture_Constants';


export const PictureContainerPreviewSlider = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: ${previewSliderElementHeight}px;
  width: ${previewSliderElementWidth}px;
`;


